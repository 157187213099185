$(document).ready(function(){

    var header = $('header.header');
    var sticky = header.offsetTop;
    var hamburger = $('.hamburger');
    var mobMenu = $('nav.mobile');

    hamburger.on('click', function(){
        $(this).toggleClass('is-active');
        mobMenu.toggleClass('active');
        $('body').toggleClass('no-scroll');
    });

    function makeHeaderSticky() {
        //console.log('sicky func');
        if ($(window).scrollTop() > 150) {
            header.addClass("sticky");
        } else {
            header.removeClass("sticky");
        }
    }

    window.onscroll = function() {makeHeaderSticky()};
});





