function initMap(){
    var googleMap = $('#map');
    var mapLat = googleMap.data("lat");
    var mapLng = googleMap.data("lng");
    var position = {lat: mapLat, lng: mapLng};
    var zoom = parseInt(googleMap.data("zoom"));
    var map = new google.maps.Map(document.getElementById('map'), {
        center: {lat: parseFloat(mapLat), lng: parseFloat(mapLng)},
        zoom: zoom
        // zoom: parseInt(gmapsZoom),//comes from map_zoom in cms
    });

    var marker = new google.maps.Marker({position: position, map: map});
}

$(function(){
    if($('*[data-provide="google-map"]').length!=0){
        loadScript('https://maps.googleapis.com/maps/api/js?key='+gmapsKey+'&callback=initMap&v=3.exp&use_slippy=true');
    }
    function loadScript(url){
        var script = document.createElement("script"); // Make a script DOM node
        script.src = url; // Set it's src to the provided URL
        document.body.appendChild(script); // Add it to the end of the head section of the page (could change 'head' to 'body' to add it to the end of the body section instead)
    }
});