$(document).ready(function () {

    $('a[href$=".pdf"]').attr('target', '_blank')

    $("a.track-pdf-click[href$='.pdf']").on("click", function (e) {
        var url = $(this).attr('href')
        var name = url.substring(url.lastIndexOf('/') + 1);
        gtag('event', 'click', {
            'event_category': 'PDF Link',
            'event_label': name,
            'value': 1
        });
    });

    const player = new Plyr('#player');

    $('.explore-more').on('click', function () {
        $('html,body').animate({
                scrollTop: $('.what-to-expect').offset().top
            },
            'slow');
    });
});
