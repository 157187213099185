$(document).ready(function(){
    $('.timeline .line').on('click', function(){
        var event = $(this).data('event');
        var eventModal = $('.timeline-model[data-event="'+event+'"]');

        eventModal.addClass('open');

    });

    $('.timeline .timeline-model .close-modal').on('click', function(){
        $(this).parent('.timeline-model').removeClass('open');

    });
});



