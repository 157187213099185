$(document).ready(function(){
    $('.homepage-hero-slider').slick({
        slidesToShow: 1,
        arrows: true,
        nextArrow: $('.homepage-hero-slider .next-arrow'),
        prevArrow: $('.homepage-hero-slider .prev-arrow'),
        autoplay: true,
        autoplaySpeed: 5000
    });
});




