$(document).ready(function(){
    $('.accordion .accordion-header').on('click', function(){
        $(this).siblings('.accordion-body').toggleClass('open');

        if($(this).siblings('.accordion-body').hasClass('open')){
            $(this).find('i').removeClass('fa-plus').addClass('fa-minus');
        } else{
            $(this).find('i').removeClass('fa-minus').addClass('fa-plus');
        }
    });
});